<template>
  <div
    :class="[
      'w-full',
      'h-[58px] px-[12px]',
      'lg:h-[84px] lg:px-[24px] lg:pt-[12px]',
      'flex items-center',
      'transition transition-all duration-200 ease-in-out',
    ]"
  >
    <div class="w-full flex items-center justify-between h-[60px]">
      <slot/>

    </div>
  </div>
</template>
