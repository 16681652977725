<script setup lang="ts">
import { faBars, faUser } from '@fortawesome/pro-light-svg-icons'

import type { CurrentUser } from '@consumer/types/serializers'

const open = defineModel<{ open: boolean }>()

const currentUser = inject<CurrentUser | null>('currentUser')
</script>

<template>
  <button
    :class="[
      'flex flex-row items-center justify-center gap-[12px] h-[44px] w-[76px]',
      'rounded-v2md border-1 border-solid border-v2-grey-cool',
      'active:text-v2-primary lg:hover:text-v2-primary',
      open ? 'text-v2-primary' : 'text-v2-secondary'
    ]"
  >
    <div
      v-if="currentUser?.initials"
      :class="[
        'text-[12px] text-v2-blue-royal font-semibold',
        'w-[26px] h-[26px] bg-v2-blue-cool rounded-v2sm',
        'flex items-center justify-center',
      ]"
    >
      {{ currentUser.initials }}
    </div>
    <V2Icon v-else :icon="faUser" size="md"/>
    <V2Icon :icon="faBars" size="md"/>
  </button>
</template>
