<script setup lang="ts">
import { uniqueId } from '@consumer/helpers/string'

withDefaults(defineProps<{
  name?: string
  id?: string
  label?: string
  icon?: any | null
  iconRight?: any | null
  placeholder?: string
  compact?: boolean
  type?: string
  enterkeyhint?: string
}>(), { type: 'text', })

const model = defineModel<string>()

const inputId = computed(() => __props.id ?? uniqueId('v2-input-field-'))

interface Emit {
  (event: 'focus', value: Event): void
  (event: 'blur', value: Event): void
  (event: 'input', value: Event): void
}
const emit = defineEmits<Emit>()

const slots = useSlots()

const hasAddonLeft = computed(() => Boolean(slots.addonLeft || __props.icon))

const hasAddonRight = computed(() => Boolean(slots.addonRight || __props.iconRight))

const inputEl = ref<HTMLInputElement>()

defineExpose({
  focus: () => inputEl.value?.focus(),
  blur: () => inputEl.value?.blur(),
})
</script>

<template>
  <fieldset class="v2-input-field group relative w-full">
    <slot name="label">
      <V2Label :for="inputId">
        <span v-html="label"/>
      </V2Label>
    </slot>
    <div class="v2-input-field-group relative">
      <div
        v-if="hasAddonLeft"
        :class="[
          'absolute inset-y-0 left-0 pl-4 flex items-center'
        ]"
      >
        <slot name="addonLeft">
          <V2Icon
            v-if="icon"
            :icon="icon"
            size="sm"
            class="v2-input-field-icon v2-input-field-icon-left text-v2-secondary"
          />
        </slot>
      </div>

      <input
        ref="inputEl"
        v-model="model"
        :type="type"
        :name="name"
        :placeholder="placeholder"
        :class="[
          'text-[16px] text-v2-primary placeholder-v2-secondary',
          'outline-none rounded-v2md shadow-v2sm',
          'border-1 border-solid border-v2-grey-cool',
          'h-full w-full flex items-center p-[16px]',
          'focus:bg-v2-blue-alice',
          hasAddonLeft ? 'pl-[44px]' : '',
          hasAddonRight ? 'pr-[44px]' : '',
          model ? 'font-semibold' : 'font-medium',
          'v2-input-field-input'
        ]"
        v-bind="$attrs"
        autocomplete="off"
        data-1p-ignore
        :enterkeyhint="enterkeyhint"
        @focus="emit('focus', $event)"
        @blur="emit('blur', $event)"
        @input="emit('input', $event)"
      >

      <div
        v-if="hasAddonRight"
        :class="[
          'absolute inset-y-0 right-0 pr-4 flex items-center'
        ]"
      >
        <slot name="addonRight">
          <V2Icon
            v-if="iconRight"
            :icon="iconRight"
            size="sm"
            class="v2-input-field-icon v2-input-field-icon-right text-v2-secondary"
          />
        </slot>
      </div>
    </div>
  </fieldset>
</template>

<style lang="css">
.v2-input-field-input::placeholder {
  transform: translateY(2px);

  /* line-height:600px; */
}
</style>
